import './page404.scss';
import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

export const Page404: React.FC = () => {
    const navigate = useNavigate();

    const response404 = document.createElement('meta');
    response404.setAttribute('name', 'robots');
    response404.setAttribute('content', 'noindex');

    useEffect(() => {
        document.head.appendChild(response404);
    }, [response404]);

    return (
        <div className="page-404">
            <h1>404</h1>
            <h2>Page Not Found</h2>
            <button onClick={() => navigate('/')}>Back to Homepage</button>
        </div>
    );
};
