import { getLangFromUrl } from '../../../utils/getLangFromUrl';
import './language.scss';

function Language() {
    const { lang } = getLangFromUrl();
    return (
        <div className="language-wrapper">
            <h2>{lang.pages.cv.languages.title}</h2>
            <ul>
                {lang.pages.cv.languages.all.map(({ language, level }, index) => (
                    <li key={index}>
                        {language} {level}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Language;
