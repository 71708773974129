import { getAbsolutePath } from '../../utils/getAbsolutePath';
import './Messenger.scss';

export enum MesType {
    Telegram = 'Telegram',
    Viber = 'Viber',
    WhatsApp = 'WhatsApp',
    Linkedin = 'Linkedin',
}

const config: Record<MesType, string> = {
    [MesType.Telegram]: 'https://t.me/BelovaVic',
    [MesType.Viber]: 'viber://chat?number=%2B375447774253',
    [MesType.WhatsApp]: 'https://wa.me/48452398093',
    [MesType.Linkedin]: 'https://www.linkedin.com/in/victoria-belova-0236b0223/',
};

type Props = {
    type: MesType;
    text?: boolean;
};

export const Messenger = ({ type, text = false }: Props) => {
    const href = config[type];
    const logo = getAbsolutePath(`social/${type.toLowerCase()}.png`);

    return text ? (
        <div className="soc-wrapper">
            <a className="social ico soc-pad" href={href} title={type} target="_blank" rel="noreferrer nofollow">
                <span>{type}</span>
                <img src={logo} alt={type} />
            </a>
        </div>
    ) : (
        <a className="ico" href={href} title={type} target="_blank" rel="noreferrer nofollow">
            <img src={logo} alt={type} />
        </a>
    );
};
