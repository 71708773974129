import './education.scss';
import { Image } from '../../image/image';
import { getLangFromUrl } from '../../../utils/getLangFromUrl';
import { getLinkFromSentence } from '../../../utils/getLinkFromSentence';

function Education() {
    const { lang } = getLangFromUrl();

    return (
        <div className="education-wrapper" id={getLinkFromSentence(lang.pages.cv.courses.title)}>
            <h2>{lang.pages.cv.courses.title}</h2>
            <div className="kurses-block">
                {lang.pages.cv.courses.all.map((edu, index) => (
                    <div className="kurse-wrapper" key={index}>
                        <div className="kurse-item">
                            <h3>{edu.years}</h3>
                            {edu.url ? (
                                <a href={edu.url} target="_blank" rel="noreferrer nofollow">
                                    {edu.nameUniversity}
                                </a>
                            ) : (
                                <span>{edu.nameUniversity}</span>
                            )}
                            <h4>
                                {edu.specialityTitle}: {edu.speciality}
                            </h4>
                        </div>
                        <div className="kurse-cert">
                            {edu.certPath && <Image className="certificate" src={edu.certPath} alt={edu.nameUniversity}></Image>}
                        </div>
                    </div>
                ))}
            </div>
            <div className="high-education-block" id={getLinkFromSentence(lang.pages.cv.university.title)}>
                <h2>{lang.pages.cv.university.title}</h2>
                {lang.pages.cv.university.all.map((univer, index) => (
                    <div className="high-edu" key={index}>
                        <h3>{univer.years}</h3>
                        <a href={univer.url} target="_blank" rel="noreferrer nofollow">
                            {univer.nameUniversity}
                        </a>
                        <h4>
                            {univer.specialityTitle}: {univer.speciality}
                        </h4>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Education;
