import './main.scss';
import Header from './header/header';
import General from './general/general';
import Experience from './experience/experience';
import Education from './education/education';
import Interests from './interests/interests';

function Main() {
    return (
        <main className="professional-data">
            <Header></Header>
            <General></General>
            <Experience></Experience>
            <Education></Education>
            <Interests></Interests>
        </main>
    );
}

export default Main;
