import { useNavigate } from 'react-router-dom';
import { LANGS } from '../../langs';
import './language.scss';
import { getLangFromUrl } from '../../utils/getLangFromUrl';

export const Language: React.FC<{ isMotivation?: boolean }> = ({ isMotivation }) => {
    const { code, lang } = getLangFromUrl();
    const navigate = useNavigate();

    const changeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const urlPagePath = window.location.pathname.split('/').reverse();
        const newCode = event.target.value;

        const link = urlPagePath.length < 3 ? null : Object.entries(lang.pages).find(([name, page]) => page.path === urlPagePath[0])?.[0];

        if (link) {
            const path = `/${newCode}/${LANGS[newCode as keyof typeof LANGS].pages[link as keyof (typeof LANGS)['en']['pages']].path}`;
            navigate(path);
        } else {
            navigate(`/${newCode}`);
        }

        localStorage.setItem('Language', newCode);
    };

    return (
        <div className="header-lang">
            <label htmlFor="languageSelect">Select Language:</label>
            <select id="languageSelect" className="lang-span" onChange={changeLanguage} value={code || ''}>
                {Object.entries(LANGS).map(([langCode]) => (
                    <option value={langCode} key={langCode}>
                        {langCode.toUpperCase()}
                    </option>
                ))}
            </select>
        </div>
    );
};
