import { getLangFromUrl } from '../utils/getLangFromUrl';
import { getLinkFromSentence } from '../utils/getLinkFromSentence';
import { Lang } from './types';

export const CV_PDFPrefix = 'CV';
export const motivation_PDFPrefix = 'motivation';

export const certPL_1 = '/cert/pl_s_min.jpg';
export const certBY_1 = '/cert/by_s_min.jpg';
export const certRS = '/cert/rs_min.jpg';

export const email = `vacova1988@gmail.com`;
export const tel = `+48 452 398 093`;
export const site = `vicbelova.com`;
export const levelPL = `B2+`;
export const levelENG = `B1`;

export function getCVLinks(pageCV: Lang['pages']['cv']): { text: string; path: string }[] {
    return [{ text: pageCV.experience.title }, { text: pageCV.university.title }, { text: pageCV.interests.title }].map((link) => ({
        ...link,
        path: `#${getLinkFromSentence(link.text)}`,
    }));
}

export function getLetterLinks(pageLetter: Lang['pages']['letter']) {
    const { lang, code } = getLangFromUrl();
    return [{ text: `← ${pageLetter.backBtnName}`, path: `/${code}/${lang.pages.cv.path}` }];
}
