import './general.scss';
import { getLangFromUrl } from '../../../utils/getLangFromUrl';

function General() {
    const { lang } = getLangFromUrl();
    return (
        <div className="general-wrapper">
            <p>{lang.pages.cv.general}</p>
        </div>
    );
}

export default General;
