import { letter } from './pages/letter';
import { cv } from './pages/cv';
import { email, site, tel } from '../constants';
import { start } from './pages/start';

export const pl = {
    name: `Wiktoria Belova`,
    speciality: 'Księgowa',
    contacts: {
        title: 'DANE OSOBOWE',
        ageTitle: 'Rok urodzenia',
        country: `Wrocław, Polska`,
        email,
        tel,
        site,
    },
    seo: {
        title: 'Księgowa, rachunkowość i finanse | Szukam pracy w Polsce',
        description:
            'Księgowa z umiejętnościami w rachunkowości, finansach i rachunkowości zarządczej. Samodzielna księgowość. Sprawdź CV księgowego',
    },
    pages: { cv, letter, start },
};
