import './interests.scss';
import { getLangFromUrl } from '../../../utils/getLangFromUrl';
import { getLinkFromSentence } from '../../../utils/getLinkFromSentence';

function Interests() {
    const { lang } = getLangFromUrl();
    return (
        <div className="interests-wrapper" id={getLinkFromSentence(lang.pages.cv.interests.title)}>
            <h2>{lang.pages.cv.interests.title}</h2>
            <p>{lang.pages.cv.interests.all.join(', ')}</p>
            <div className="conset-wrapper">
                <div className="footer-wrapper">
                    <h3>{lang.pages.cv.consent}</h3>
                    <h4 className="signature">{lang.name}</h4>
                </div>
            </div>
        </div>
    );
}

export default Interests;
