import './header.scss';
import Avatar from './avatar/avatar';
import Contacts from './contacts/contacts';
import Skills from './skills/skills';
import Language from './language/language';

function Header() {
    return (
        <header className="personal-data">
            <Avatar></Avatar>
            <Contacts></Contacts>
            <Skills></Skills>
            <Language></Language>
        </header>
    );
}

export default Header;
