import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { LANGS } from './langs';
import { Page404 } from './pages/404/page404';
import { Letter } from './pages/letter/letter';
import { Start } from './pages/start/start';
import { CV } from './pages/cv/CV';
import { MAIN_PAGE } from './config';

export const AppRouter: React.FC = () => {
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Routes>
                {Object.entries(LANGS).map(([code, ln], i) => {
                    const pathMain = `/${code}`;
                    const pathCV = `/${code}/${ln.pages.cv.path}`;
                    const pathLetter = `/${code}/${ln.pages.letter.path}`;
                    const pathStart = `/${code}/${ln.pages.start.path}`;
                    return (
                        <>
                            <Route key={pathMain + i} path={pathMain} element={<MAIN_PAGE />} />
                            <Route key={pathCV + i} path={pathCV} element={<CV />} />
                            <Route key={pathLetter + i} path={pathLetter} element={<Letter />} />
                            <Route key={pathStart + i} path={pathStart} element={<Start />} />
                        </>
                    );
                })}
                <Route key={'/'} path="/" element={<MAIN_PAGE />} />
                <Route key={'*'} path="*" element={<Page404 />} />
            </Routes>
        </BrowserRouter>
    );
};
