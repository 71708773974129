import { getLangFromUrl } from '../../../utils/getLangFromUrl';
import './skills.scss';

function Skills() {
    const { lang } = getLangFromUrl();
    return (
        <div className="skills-wrapper">
            <h2>{lang.pages.cv.skills.title}</h2>
            <ul>
                {lang.pages.cv.skills.all.map((skill, index) => (
                    <li key={index}>{skill}</li>
                ))}
            </ul>
        </div>
    );
}

export default Skills;
