import * as Constants from '../../constants';

export const letter = {
    get links(): ReturnType<typeof Constants.getLetterLinks> {
        return Constants.getLetterLinks(this);
    },
    PDFTitle: 'Download PDF',
    PDFPrefix: Constants.motivation_PDFPrefix,
    path: 'motivational-letter',
    backBtnName: 'Back to CV',
    intro: [
        `Dear Ladies and Gentlemen!`,
        `I am a specialist in the field of accounting, eager to develop my career in a dynamic business environment. 
      I gained my professional experience in Belarus, where I worked as an independent accountant and a tax inspector. Working in these positions allowed me to 
      acquire comprehensive knowledge and skills in the areas of accounting and taxation.`,
    ],

    experience: ``,

    taxInspector: `During my eight years working at the tax office, I conducted tax audits of legal entities, which deepened my understanding of the practical aspects of 
      accounting, tax, and administrative law. In this position, I developed qualities such as responsibility, the ability to work effectively in a large team, work under 
      time constraints and multitasking, as well as the ability to make effective decisions. For my professionalism and achievements at work, I received awards from the head 
      of the tax service twice`,

    accountent: [
        `During my five years working as an independent accountant, I was responsible for the comprehensive management of full accounting. My duties ranged from 
      daily operational activities, which included collaboration with both contractors and external state institutions, to the preparation of tax returns and financial statements. 
      My experience includes systematic analysis of financial data, ensuring the accuracy and timeliness of financial reports, which are essential for making strategic decisions 
      by the company.`,
        `Additionally, I have gained experience in servicing entrepreneurs without legal personality by managing comprehensive income and expenditure books and current tax accounting.`,
    ],

    hobby: /*`In addition to developing my professional qualifications, I also strive to broaden my interests and personal skills. Therefore, being interested in programming 
      and web development, I participated in a training course in the Java Script programming language. These skills allow me to move smoothly between different types of 
      activities and contribute to the development of thinking in various directions.`*/ '',

    development: {
        developmentPL: `Since 2022, I have been living in Poland on a temporary residence permit with the right to work. I am actively working on enhancing my qualifications 
        and familiarizing myself with the Polish accounting system."`,

        devRbPl: `I completed a course in full accounting (Level I) at the Association of Accountants in Poland (Lower Silesian Branch in Wrocław), which provided me with a 
        solid foundation in Polish accounting. Additionally, I am continuing my studies in the second level program (independent accountant) to deepen my knowledge and skills.
        Furthermore, to better understand Polish legislation, I am currently studying Administration at the Cosinus post-secondary school, where I am learning labor law, civil law,
        administrative law, and other fields.`,

        target: ``,
    },

    results: [
        `In conclusion, I would like to emphasize my genuine interest in working in Poland and my readiness for further professional development in this country. 
      I am convinced that my experience and skills can be valuable to companies operating on the Polish market. I am ready to take on challenges and actively develop my competences 
      to benefit both me and my future employer.`,
        /*`If you are interested in my candidacy, please read the details of my CV, which can be found on the website https://vicbelova.com.`*/
    ],

    ending: `With respect, Victoria Belova`,
};
