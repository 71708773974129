import React from 'react';
import Header from '../../components/header/header';
import Main from '../../components/main/main';
import { isPdfMode } from '../../utils/isPdfMode';
import NavBar from '../../components/navBar/navBar';

export const CV: React.FC = () => {
    return (
        <>
            {!isPdfMode() && <NavBar listIco={true} />}
            <Header />
            <Main />
        </>
    );
};
