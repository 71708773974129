import { getAbsolutePath } from '../../../utils/getAbsolutePath';
import { getLangFromUrl } from '../../../utils/getLangFromUrl';
import { isPdfMode } from '../../../utils/isPdfMode';
import { MesType, Messenger } from '../../social/Messenger';
import './contacts.scss';

const googleMapsLink = `https://www.google.com/maps/place/Wroc%C5%82aw,+Poland/@51.1271567,16.9094639,12z/data=!3m1!4b1!4m6!3m5!1s0x470fe9c2d4b58abf:0xb70956aec205e0f5!8m2!3d51.1078852!4d17.0385376!16zL20vMDg0NWI`;
const webSite = 'https://vicbelova.com/';

function Contacts({ styles }: { styles?: Record<string, string> }) {
    const { lang } = getLangFromUrl();
    return (
        <div className={styles?.wrapper || 'contacts-wrapper'}>
            <h2>{lang.contacts.title}</h2>
            <div className="contacts-content">
                {isPdfMode() && (
                    <div className="contact-item">
                        <img src={getAbsolutePath('icons/web.svg')} alt="Web Site" />
                        <a href={webSite} target="blank">
                            vicbelova.com
                        </a>
                    </div>
                )}

                <div className="contact-item">
                    <img src={getAbsolutePath('icons/age.svg')} alt="Location ico" />
                    <div className="age-line">{lang.contacts.ageTitle}: 1988</div>
                </div>

                <div className="contact-item">
                    <img src={getAbsolutePath('icons/location.svg')} alt="Location ico" />
                    <a href={googleMapsLink} target="blank" rel="noreferrer nofollow">
                        {lang.contacts.country}
                    </a>
                </div>

                <div className="contact-item">
                    <img src={getAbsolutePath('icons/e-mail.svg')} alt="E-mail ico" />
                    <a href={`mailto: ${lang.contacts.email}`} target="blank" rel="noreferrer nofollow">
                        {lang.contacts.email}
                    </a>
                </div>

                <div className="contact-item">
                    <img src={getAbsolutePath('icons/phone.svg')} alt="Phone ico" />
                    <a href={`tel: ${lang.contacts.tel}`} target="blank" rel="noreferrer nofollow">
                        {lang.contacts.tel}
                    </a>
                </div>

                <div className="social-btns">
                    <Messenger type={MesType.Linkedin}></Messenger>
                    <Messenger type={MesType.WhatsApp}></Messenger>
                    <Messenger type={MesType.Viber}></Messenger>
                    <Messenger type={MesType.Telegram}></Messenger>
                </div>
            </div>
        </div>
    );
}

export default Contacts;
