import React from 'react';
import './header.scss';
import { getLangFromUrl } from '../../../utils/getLangFromUrl';

const Header: React.FC = () => {
    const { lang } = getLangFromUrl();
    return (
        <div className="header-wrapper">
            <div className="header-logo">
                <h1>{lang.name}</h1>
                <h2>{lang.speciality}</h2>
            </div>
        </div>
    );
};

export default Header;
