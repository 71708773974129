import './avatar.scss';

function Avatar() {
    return (
        <div className="avatar-wrapper">
            <div className="photo-block">
                <img src="/photo.jpg" alt="Victoria Belova"></img>
            </div>
        </div>
    );
}

export default Avatar;
