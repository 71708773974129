import './start.scss';
import { isPdfMode } from '../../utils/isPdfMode';
import NavBar from '../../components/navBar/navBar';
import { getLangFromUrl } from '../../utils/getLangFromUrl';

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setMetaTags } from '../../utils/setMetaTags';
import { remove404meta } from '../../utils/remove404meta';
import { Link } from 'react-router-dom';

export const Start: React.FC = () => {
    const { lang, code } = getLangFromUrl();
    const navigate = useNavigate();

    useEffect(() => {
        const savedLang = localStorage.getItem('Language');
        const redirect = localStorage.getItem('Redirect');

        if (redirect) {
            localStorage.removeItem('Redirect');
            navigate(redirect);
        } else if (savedLang && savedLang !== 'pl') {
            navigate(`/${savedLang}`);
        }

        setMetaTags(lang.seo);
        remove404meta();
    }, [code, navigate, lang.seo]);

    const linkToCV = `/${code}/cv`;

    return (
        <>
            {!isPdfMode() && (
                <NavBar
                    styles={{
                        startNavMenu: 'start-nav-menu',
                        startMainMenu: 'start-main-menu',
                        startMenu: 'start-menu',
                        startMenuList: 'start-menu-list',
                        startMenuItem: 'srart-menu-item',
                    }}
                />
            )}

            <div className="start-wrapper">
                <div className="start-content-wrapper">
                    <div className="start-block">
                        <div className="start-logo">
                            <h1> {lang.name} </h1>
                            <h2> {lang.speciality} </h2>
                        </div>
                        <div className="start-content">
                            <Link to={linkToCV}>
                                <button className="start-job-status">{lang.pages.start.status}</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
