import { MAIN_PAGE_PATH } from '../config';
import { Lang } from '../langs/types';

export function getPageFromUrl(lang: Lang, code: string) {
    const urlPagePath = window.location.pathname.split('/').reverse().filter(Boolean);

    const page = urlPagePath.length < 2 ? lang.pages[MAIN_PAGE_PATH] : Object.values(lang.pages).find((page) => page.path === urlPagePath[0]);

    const PDFPrefix = page && 'PDFPrefix' in page ? page.PDFPrefix : null;
    const PDFLink = PDFPrefix && `${PDFPrefix}_Victoria_Belova_${code}.pdf`;
    const PDFTitle = page && 'PDFTitle' in page ? page.PDFTitle : null;

    const menuLinks = page && 'links' in page ? page.links : null;

    return { page, PDFLink, PDFTitle, PDFPrefix, menuLinks };
}
