import Contacts from '../../components/header/contacts/contacts';
import NavBar from '../../components/navBar/navBar';
import { getLangFromUrl } from '../../utils/getLangFromUrl';
import { isPdfMode } from '../../utils/isPdfMode';
import './letter.scss';

export const Letter: React.FC = () => {
    const { lang } = getLangFromUrl();
    return (
        <>
            {!isPdfMode() && <NavBar />}
            <div className="letter">
                <div className="letter-wrapper">
                    <div className="letter-contacts-data">
                        <Contacts styles={{ wrapper: 'letter-contacts-wrapper' }}></Contacts>
                    </div>

                    <div className="letter-content-wrapper">
                        <div className="letter-block">
                            <div className="letter-intro">
                                {lang.pages.letter.intro.map((text) => (
                                    <p>{text}</p>
                                ))}
                            </div>

                            <div className="letter-expirience">
                                <p>{lang.pages.letter.experience} </p>
                                <div className="letter-tax-inspector">
                                    <p> {lang.pages.letter.taxInspector} </p>
                                </div>
                                <div className="letter-accountent-bel">
                                    {lang.pages.letter.accountent.map((text) => (
                                        <p>{text}</p>
                                    ))}
                                </div>
                            </div>
                            <div className="letter-development">
                                <p>{lang.pages.letter.development.developmentPL}</p>
                                <p>{lang.pages.letter.development.devRbPl}</p>
                                <p>{lang.pages.letter.development.target}</p>
                            </div>

                            <div className="letter-hobby">
                                <p>{lang.pages.letter.hobby}</p>
                            </div>

                            <div className="letter-results">
                                {lang.pages.letter.results.map((text) => (
                                    <p>{text}</p>
                                ))}
                            </div>

                            <div className="letter-ending">
                                <p>{lang.pages.letter.ending}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
