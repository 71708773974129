import { getLangFromUrl } from '../../../utils/getLangFromUrl';

export const start = {
    get links() {
        const { lang, code } = getLangFromUrl();
        return [
            { text: 'About Me', path: `/${code}/${lang.pages.letter.path}` },
            { text: 'Open CV', path: `/${code}/${lang.pages.cv.path}` },
        ];
    },
    path: 'start',
    status: `Open to work`,
};
