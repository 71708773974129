import { Lang } from '../langs/types';

export function setMetaTags({ title, description }: Lang['seo']) {
    document.title = title;

    const metaDescription = document.querySelector("meta[name='description']");
    if (metaDescription) {
        metaDescription.setAttribute('content', description);
    }
}
