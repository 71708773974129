import React, { useState } from 'react';
import { Language } from '../language/language';
import './navBar.scss';
import { getLangFromUrl } from '../../utils/getLangFromUrl';
import { getPageFromUrl } from '../../utils/getPageFromUrl';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const NavBar: React.FC<{ styles?: Record<string, string>; listIco?: boolean }> = ({ listIco, styles }) => {
    const navigate = useNavigate();
    const { lang, code } = getLangFromUrl();
    const pageData = getPageFromUrl(lang, code);

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    const letterTite = lang.pages.letter.path.split('-').join(' ');
    const letterLink = `/${code}/${lang.pages.letter.path}`;

    const goToMainPage = () => navigate(`/${code}`);

    return (
        <nav>
            <div className={styles?.startMainMenu ?? 'main-menu'}>
                <div className="logo-name" onClick={goToMainPage}>
                    <div className="name">{lang.name}</div>
                    <div className="specialization">{lang.speciality}</div>
                </div>
                <div className={styles?.startMenu ?? 'menu'}>
                    <input type="checkbox" id="burger-checkbox" className="burger-checkbox" checked={isMenuOpen} onChange={toggleMenu} />
                    <label htmlFor="burger-checkbox" className="burger"></label>
                    <ul className={styles?.startMenuList ?? 'menu-list'}>
                        {pageData.menuLinks &&
                            pageData.menuLinks.map(({ text, path }) => (
                                <li>
                                    {path.startsWith('#') ? (
                                        <a href={path} className="menu-item" onClick={() => setIsMenuOpen(false)}>
                                            {text}
                                        </a>
                                    ) : (
                                        <Link to={path} className="menu-item" onClick={() => setIsMenuOpen(false)}>
                                            {text}
                                        </Link>
                                    )}
                                </li>
                            ))}
                        {listIco && (
                            <li>
                                <Link to={letterLink} className="menu-item" onClick={() => setIsMenuOpen(false)}>
                                    <img src="/icons/letter.png" alt={letterTite} className="letter-ico" title={letterTite} />
                                </Link>
                            </li>
                        )}
                        {pageData.page && pageData.PDFLink && (
                            <li>
                                <a href={`/${pageData.PDFLink}`} download className="menu-item">
                                    <img src="/icons/pdf.png" alt={pageData.PDFTitle!} className="pdf-ico" title={pageData.PDFTitle!} />
                                </a>
                            </li>
                        )}
                        <li className="menu-item">
                            <Language />
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
