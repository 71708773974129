import './experience.scss';
import { getLangFromUrl } from '../../../utils/getLangFromUrl';
import { getLinkFromSentence } from '../../../utils/getLinkFromSentence';

function Experience() {
    const { lang } = getLangFromUrl();
    return (
        <div className="experience-wrapper" id={getLinkFromSentence(lang.pages.cv.experience.title)}>
            <h2>{lang.pages.cv.experience.title}</h2>
            {lang.pages.cv.experience.all.map(({ year, place, profession, experiences }, index) => (
                <div key={index} className="experience-item">
                    <h3>{year}</h3>
                    <h4>{place}</h4>
                    <h5>{profession}</h5>
                    <ul>
                        {experiences.map((experience, index) => (
                            <li key={index}>{experience}</li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
}

export default Experience;
