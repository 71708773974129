import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './image.scss';

export const Image: React.FC<React.ImgHTMLAttributes<HTMLImageElement>> = ({ className, src, alt }) => {
    const [isZoomed, setIsZoomed] = useState(false);
    const zoomImage = () => setIsZoomed(!isZoomed);
    const srcBigSize = src?.replace('_min.', '.');
    return (
        <>
            <img className={className} src={src} alt={alt} onClick={zoomImage} />
            {isZoomed &&
                ReactDOM.createPortal(
                    <>
                        <div className="full-screen">
                            <div className="close-button" onClick={zoomImage}></div>
                            <img className="full-img" src={srcBigSize} alt={alt} />
                        </div>
                    </>,
                    document.body,
                )}
        </>
    );
};
