import { letter } from './pages/letter';
import { cv } from './pages/cv';
import { Lang } from '../types';
import { email, site, tel } from '../constants';
import { start } from './pages/start';

export const en: Lang = {
    name: `Victoria Belova`,
    speciality: 'Accountant',
    contacts: {
        title: 'PERSONAL DATA',
        ageTitle: 'Year of birth',
        country: `Wroclaw, Poland`,
        email,
        tel,
        site,
    },
    seo: {
        title: 'Accountant, Accounting and Finance | Looking for a Job in Poland',
        description: "An accountant with skills in accounting, finance, and management accounting. Independent accounting. Check the accountant's CV",
    },
    pages: { cv, letter, start },
};
