import * as Constants from '../../constants';

export const letter = {
    get links(): ReturnType<typeof Constants.getLetterLinks> {
        return Constants.getLetterLinks(this);
    },
    PDFTitle: 'Ściągnij PDF',
    PDFPrefix: Constants.motivation_PDFPrefix,
    path: 'list-motywacyjny',
    backBtnName: 'Powrót do CV',

    intro: [
        `Szanowni Państwo!`,
        `Jestem specjalistą w dziedzinie księgowości, pragnącym rozwijać swoją karierę w dynamicznym środowisku biznesowym. 
      Swoje doświadczenie zawodowe zdobywałam w Białorusi, gdzie pracowałam jako samodzielny księgowa oraz inspektor podatkowy. Praca na tych 
      stanowiskach pozwoliła mi zdobyć wszechstronną wiedzę i umiejętności w obszarze księgowości i podatków.`,
    ],

    experience: ``,

    taxInspector: `Podczas ośmioletniej pracy w urzędzie skarbowym prowadziłam kontrole podatkowe osób prawnych, co przyczyniło się do pogłębienia mojego 
    zrozumienia praktycznych aspektów prawa rachunkowego, podatkowego i administracyjnego. Na tym stanowisku rozwijałam cechy takie jak odpowiedzialność, 
    umiejętność efektywnej pracy w dużym zespole, praca w ograniczonym czasie i przy wielozadaniowości, a także umiejętność podejmowania skutecznych decyzji. 
    Za mój profesjonalizm i osiągnięcia w pracy dwukrotnie otrzymałam nagrody od szefa służby podatkowej.`,

    accountent: [
        `Podczas pięcioletniej pracy jako samodzielny księgowa, byłam odpowiedzialna za kompleksowe prowadzenie pełnej księgowości. Moje obowiązki rozpoczynały się 
      od codziennej działalności operacyjnej, obejmującej współpracę zarówno z kontrahentami, jak i z zewnętrznymi instytucjami państwowymi, a kończyły się na sporządzaniu 
      deklaracji podatkowych i sprawozdań finansowych. Moje doświadczenie obejmuje systematyczną analizę danych finansowych, co gwarantuje dokładność i aktualność sprawozdań 
      finansowych, niezbędnych dla podejmowania strategicznych decyzji przez firmę.`,

        `Dodatkowo, zdobyłam doświadczenie w obsłudze przedsiębiorców niemających osobowości prawnej, prowadząc kompleksowe księgi przychodów i rozchodów oraz bieżącą 
      rachunkowość podatkową.`,
    ],

    hobby: /*`Poza rozwijaniem moich kwalifikacji zawodowych, staram się również poszerzać swoje zainteresowania i umiejętności osobiste. Dlatego też, interesując 
    się programowaniem i tworzeniem stron internetowych, wziąłem udział w szkoleniu z języka programowania Java Skrypt. Te umiejętności pozwalają mi swobodnie przemieszczać 
    się między różnymi rodzajami aktywności, co sprzyja rozwojowi myślenia w różnych kierunkach.`*/ '',

    development: {
        developmentPL: `Od 2022 roku mieszkam w Polsce na podstawie zezwolenia na pobyt czasowy z dostępem do rynku pracy. Intensywnie pracuję nad podniesieniem swoich kwalifikacji i 
      poznawaniem polskiego systemu księgowego.`,
        devRbPl: `Ukończyłam kursy z zakresu pełnej księgowości (I stopień) w Stowarzyszeniu Księgowych w Polsce (Oddział Dolnośląski we Wrocławiu), co umożliwiło mi zdobycie solidnych 
      podstaw w dziedzinie polskiej księgowości. Ponadto, kontynuuję naukę na studiach II stopnia (samodzielny księgowa), aby pogłębić swoją wiedzę i umiejętności. Dodatkowo, 
      w celu lepszego zrozumienia polskiego ustawodawstwa, obecnie studiuję na kierunku Administracja w szkole policealnej Cosinus, gdzie uczę się prawa pracy, prawa cywilnego,
      prawa administracyjnego i innych dziedzin.`,

        target: ``,
    },

    results: [
        `W podsumowaniu chciałabym podkreślić moje autentyczne zainteresowanie pracą w Polsce oraz gotowość do dalszego rozwoju zawodowego w tym kraju. Jestem przekonana, że moje 
     doświadczenie i umiejętności mogą być wartościowe dla firm działających na polskim rynku. Jestem gotowa podejmować wyzwania i aktywnie rozwijać swoje kompetencje, 
     aby przynosić korzyści zarówno mnie, jak i przyszłemu pracodawcy.`,
        /* `Jeśli moja kandydatura Państwa zainteresowała, proszę zapoznać się ze szczegółami mojego CV, które znajdują się na stronie internetowej https://vicbelova.com. `*/
    ],

    ending: `Z wyrazami szacunku, Wiktoria Belova`,
};
